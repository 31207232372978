import { Link } from 'gatsby'
import React, { FunctionComponent, useState } from 'react'
import styled from 'styled-components'
import Button from '../components/button'
import Header from '../components/header'
import SEO from '../components/seo'
import {
  BottomSemiCircle,
  Circle,
  TopLeftSemiCircle
} from '../components/shape'
import Title from '../components/title'
import { Bold, SmallText, Title3, Title4 } from '../components/typography'
import { VisibilitySensor } from '../components/visibility-sensor'
import { WorldMap } from '../components/worldmap'
import Gilliam from '../images/pictures/gilliam.jpeg'
import Lies from '../images/pictures/lies.jpg'
import Tom from '../images/pictures/tom.png'
import Arrow from '../images/svg/arrow.svg'
import Etch from '../images/svg/etch-green-gum.svg'
import { resetPositionAnimation } from '../styles/animations'
import Layout from './layout'

const Main = styled.main`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const TitleContainer = styled.div<{ isVisible: boolean }>`
  flex: 1;
  padding: 2.5rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop}
    9.125rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop};

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
  }

  /* WTF why doesn't this work as a styled(Title) */
  h2 {
    margin-bottom: 3.75rem;
    @media screen and (max-width: ${({ theme: { mobileSize } }) =>
        mobileSize}) {
      margin-bottom: 1.875rem;
    }
  }

  opacity: ${({ isVisible }) => Number(isVisible)};
  transition: opacity 2s;
`

const WhoWeAreText = styled.p`
  margin-bottom: 3rem;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    text-align: center;
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
  }
`

const WhatWeDoLink = styled(Link)`
  font-size: 1rem;
  > svg {
    height: 1rem;
    width: 1.5rem;
    margin-left: 0.4rem;
  }
  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    font-weight: bold;
    > svg {
      height: 2.5vmin;
      width: 3.5vmin;
    }
  }
`

const LocationContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap-reverse;
  background-color: ${({ theme: { backgroundOverlay } }) => backgroundOverlay};
  padding: 5rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop};

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
    margin-top: 40vmin;
  }

  > div:first-of-type {
    flex: 1 0 27.5rem;
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};

    > h3 {
      margin-bottom: 1.4rem;
    }

    @media screen and (max-width: ${({ theme: { tabletSize } }) =>
        tabletSize}) {
      text-align: center;
    }

    @media screen and (max-width: ${({ theme: { mobileSize } }) =>
        mobileSize}) {
      flex: 1;
    }
  }

  > div:last-of-type {
    flex: 1 0 27.5rem;
    display: flex;
    justify-content: center;
    position: relative;

    @media screen and (max-width: ${({ theme: { tabletSize } }) =>
        tabletSize}) {
      height: 45vmin;
      padding-top: 5rem;
    }

    > svg {
      position: absolute;
      margin-top: -10rem;
      margin-left: auto;
      margin-right: auto;
      width: 32.375rem;
      height: 32.375rem;

      @media screen and (max-width: ${({ theme: { tabletSize } }) =>
          tabletSize}) {
        transform: translateY(-14vmin);
        width: 65vmin;
        height: 65vmin;
        left: 0;
        right: 0;
      }
    }
  }
`

const TeamContainer = styled.div`
  padding: 8rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop} 0
    ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop};

  @media screen and (max-width: ${({ theme: { tabletSize } }) => tabletSize}) {
    padding: 8rem ${({ theme: { sideMarginMobile } }) => sideMarginMobile} 0
      ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
  }
`

const TeamTitle = styled(Title3)`
  margin-bottom: 3.125rem;
`

const TeamPictures = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Column = styled.div`
  flex: 1 0 24rem;

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    flex: unset;
  }

  &:first-of-type {
    margin-right: 3rem;

    @media screen and (max-width: ${({ theme: { mobileSize } }) =>
        mobileSize}) {
      margin-right: 0;
    }
  }

  &:last-of-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

const Profile = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;

  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin-bottom: 2rem;
    z-index: 1;
  }
`

const ProfileImage = styled.img<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => Number(isVisible)};
  transition: opacity 2s;
`

const ProfileTitle = styled(Title4)`
  line-height: 3.5rem;
`

const NotOnlyWeContainer = styled.div`
  padding: 4.1rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop}
    8rem ${({ theme: { sideMarginDesktop } }) => sideMarginDesktop};

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    padding: 4.1rem ${({ theme: { sideMarginMobile } }) => sideMarginMobile}
      8rem ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
  }
  > div {
    display: flex;
    justify-content: center;
    margin-bottom: 2.6875rem;
  }
  > p {
    text-align: center;
  }
`

const AreYouReadyContainer = styled.div<{ isVisible: boolean }>`
  flex: 1;
  display: flex;
  padding: 3.75rem;
  background-color: ${({ theme: { pink } }) => pink};

  @media screen and (max-width: ${({ theme: { tabletSize } }) => tabletSize}) {
    padding: ${({ theme: { sideMarginMobile } }) => sideMarginMobile};
  }

  transform: translateY(100%);

  ${({ isVisible }) => isVisible && resetPositionAnimation};
`

const EtchContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    width: 25.375rem;
    height: 17.375rem;
  }

  @media screen and (max-width: ${({ theme: { mobileSize } }) => mobileSize}) {
    display: none;
  }
`

const ReadyTextContainer = styled.div`
  flex: 1;
  > h4 {
    display: block;
    font-weight: 500;
    line-height: 3.5rem;
    &:last-of-type {
      margin-bottom: 2.1875rem;
    }

    @media screen and (max-width: ${({ theme: { mobileSize } }) =>
        mobileSize}) {
      font-size: 8vmin;
    }
  }
  > a {
    width: 12.1875rem;
  }
`

const StyledCircle = styled(Circle)`
  position: absolute;
  top: 15%;
  left: -7rem;
`

const StyledBottomSemiCircle = styled(BottomSemiCircle)`
  position: absolute;
  top: 10%;
  right: -7rem;
`

const StyledTopLeftSemiCircle = styled(TopLeftSemiCircle)`
  position: absolute;
  bottom: 20%;
  right: -8rem;
`

enum Element {
  Title,
  World,
  Tom,
  Gilliam,
  Lies,
  AreYouReady
}

const WhoWeAre: FunctionComponent = () => {
  const [visibleElements, setVisibleElements] = useState<
    { [key in Element]?: boolean }
  >({})

  return (
    <Layout>
      <SEO title="Who we are" />
      <Header />
      <Main>
        <VisibilitySensor
          onChange={visible =>
            visible &&
            setVisibleElements({
              ...visibleElements,
              [Element.Title]: true
            })
          }
        >
          <TitleContainer isVisible={!!visibleElements[Element.Title]}>
            <Title>Who we are</Title>
            <WhoWeAreText>
              We are an <Bold>amicable digital product studio</Bold> formed by a
              team of design, development and project specialists. By combining
              European expertise, Bermudian amicability and International
              inspiration, we create <Bold>world class user experiences</Bold>{' '}
              regardless of technology.
            </WhoWeAreText>
            <WhoWeAreText>
              <WhatWeDoLink to="/what-we-do" className="text">
                What we do <Arrow />
              </WhatWeDoLink>
            </WhoWeAreText>
          </TitleContainer>
        </VisibilitySensor>

        <LocationContainer>
          <div>
            <Title3>The location</Title3>
            <SmallText>
              Based in Belgium and the UK with links to Bermuda
            </SmallText>
            <SmallText> but active all over the world through the</SmallText>
            <SmallText> power of remote working.</SmallText>
          </div>
          <div>
            <VisibilitySensor
              partialVisibility={false}
              onChange={visible =>
                visible &&
                setVisibleElements({
                  ...visibleElements,
                  [Element.World]: true
                })
              }
            >
              <WorldMap
                className={
                  !!visibleElements[Element.World] ? 'animated' : undefined
                }
              />
            </VisibilitySensor>
          </div>
        </LocationContainer>

        <TeamContainer>
          <TeamTitle>The team</TeamTitle>
          <TeamPictures>
            <Column>
              <Profile>
                <VisibilitySensor
                  onChange={visible =>
                    visible &&
                    setVisibleElements({
                      ...visibleElements,
                      [Element.Tom]: true
                    })
                  }
                >
                  <ProfileImage
                    isVisible={!!visibleElements[Element.Tom]}
                    src={Tom}
                  />
                </VisibilitySensor>
                <ProfileTitle>Tom Reed</ProfileTitle>
                <SmallText>Founder & Facilitator</SmallText>
                <StyledCircle color="pink" diameter={9} />
              </Profile>
              <Profile>
                <VisibilitySensor
                  onChange={visible =>
                    visible &&
                    setVisibleElements({
                      ...visibleElements,
                      [Element.Lies]: true
                    })
                  }
                >
                  <ProfileImage
                    isVisible={!!visibleElements[Element.Lies]}
                    src={Lies}
                  />
                </VisibilitySensor>
                <ProfileTitle>Lies Bosman</ProfileTitle>
                <SmallText>Chief Creative Officer</SmallText>
                <StyledTopLeftSemiCircle color="darkPurple" diameter={12} />
              </Profile>
            </Column>
            <Column>
              <Profile>
                <VisibilitySensor
                  onChange={visible =>
                    visible &&
                    setVisibleElements({
                      ...visibleElements,
                      [Element.Gilliam]: true
                    })
                  }
                >
                  <ProfileImage
                    isVisible={!!visibleElements[Element.Gilliam]}
                    src={Gilliam}
                  />
                </VisibilitySensor>
                <ProfileTitle>Gilliam Flebus</ProfileTitle>
                <SmallText>Chief Technology Officer</SmallText>
                <StyledBottomSemiCircle color="blue" diameter={12} />
              </Profile>
            </Column>
          </TeamPictures>
        </TeamContainer>

        <NotOnlyWeContainer>
          <div>
            <h2 className="withBackground">Not only we are brux</h2>
          </div>
          <p>
            We work with a variety of specialists that enable us to create teams
            tailored to deliver brilliant experiences for your users -
            regardless of technology.
          </p>
        </NotOnlyWeContainer>

        <VisibilitySensor
          onChange={visible =>
            visible &&
            setVisibleElements({
              ...visibleElements,
              [Element.AreYouReady]: true
            })
          }
        >
          <AreYouReadyContainer
            isVisible={!!visibleElements[Element.AreYouReady]}
          >
            <EtchContainer>
              <Etch />
            </EtchContainer>
            <ReadyTextContainer>
              <Title4>Ready to see what</Title4>
              <Title4>you can accomplish</Title4>
              <Title4>in 5 days?</Title4>
              <Button link="/get-in-touch" color="darkPurple">
                Get in touch <Arrow />
              </Button>
            </ReadyTextContainer>
          </AreYouReadyContainer>
        </VisibilitySensor>
      </Main>
    </Layout>
  )
}

export default WhoWeAre

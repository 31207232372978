import React, { FunctionComponent } from 'react'
import ReactVisibilitySensor from 'react-visibility-sensor'

interface Props {
  partialVisibility?: boolean
  onChange(visible: boolean): void
}

export const VisibilitySensor: FunctionComponent<Props> = ({
  partialVisibility,
  onChange,
  children
}) =>
  typeof window !== 'undefined' ? (
    <ReactVisibilitySensor
      onChange={onChange}
      partialVisibility={
        partialVisibility !== undefined ? partialVisibility : true
      }
      offset={{ top: 40 }}
      delayedCall={true}
    >
      {children}
    </ReactVisibilitySensor>
  ) : (
    children || null
  )
